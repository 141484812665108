.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
}

.pagination .active a {
    background-color: #3182CE;
    color: white;
}

.pagination li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    margin: 0 4px;
}

.pagination li a:hover:not(.active) {
    background-color: #ddd;
}
